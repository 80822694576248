
export const TitleType = [
  { icon: "", text: "Inicio", path: "/home" },
  { icon: "", text: "Seleccion de Roles", path: "/seleccion-roles" },
  // Administracion
  { icon: "", text: "Empresa", path: "/empresas" },
  { icon: "", text: "Usuarios", path: "/usuarios" },
  { icon: "", text: "Cuentas MP", path: "/mercado_pago" },
  // Proyectos
  { icon: "", text: "Proyectos", path: "/proyectos"},
  { icon: "", text: "Rendición", path: "/proyectos/rendicion/proyecto" },  
  { icon: "", text: "Rendiciones", path: "/proyectos/rendiciones" },  
  // Eventos
  { icon: "", text: "Eventos", path: "/eventos"},  
  { icon: "", text: "Alta Eventos", path: "/eventos/alta-eventos"},  
  { icon: "", text: "Configuración Evento", path: "/eventos/configuracion-eventos"},
  { icon: "", text: "Resumen Pagos", path: "/eventos/resumen-pagos" },
  { icon: "", text: "Integrantes Evento", path: "/eventos/integrantes-evento"},
  // Inscripciones
  { icon: "", text: "Inscripciones", path: "/inscripciones" }  
];
